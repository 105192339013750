import { BaseModel } from 'modules/core/models/base-model';

const NOT_STARTED                          = 1,
      IN_PROGRESS_WRITING_HEADERS_FOOTERS  = 2,
      IN_PROGRESS_FINISHED_HEADERS_FOOTERS = 3,
      IN_PROGRESS_READING                  = 4,
      IN_PROGRESS_FINISHED_READING         = 5,
      FINISHED                             = 6,
      CANCELED                             = 7,
      INVALID                              = 8;

const IN_PROGRESS = [
    IN_PROGRESS_WRITING_HEADERS_FOOTERS,
    IN_PROGRESS_FINISHED_HEADERS_FOOTERS,
    IN_PROGRESS_READING,
    IN_PROGRESS_FINISHED_READING,
];

const IN_PROGRESS_FINISHED = [
    IN_PROGRESS_FINISHED_HEADERS_FOOTERS,
    IN_PROGRESS_FINISHED_READING,
];

export class ProcessStatus extends BaseModel {
    id      = null;
    message = null;

    static get NOT_STARTED() {
        return NOT_STARTED;
    }

    static get IN_PROGRESS_WRITING_HEADERS_FOOTERS() {
        return IN_PROGRESS_WRITING_HEADERS_FOOTERS;
    }

    static get IN_PROGRESS_FINISHED_HEADERS_FOOTERS() {
        return IN_PROGRESS_FINISHED_HEADERS_FOOTERS;
    }

    static get IN_PROGRESS_READING() {
        return IN_PROGRESS_READING;
    }

    static get IN_PROGRESS_FINISHED_READING() {
        return IN_PROGRESS_FINISHED_READING;
    }

    static get FINISHED() {
        return FINISHED;
    }

    static get CANCELED() {
        return CANCELED;
    }

    static get INVALID() {
        return INVALID;
    }

    static get IN_PROGRESS() {
        return IN_PROGRESS;
    }

    static get IN_PROGRESS_FINISHED() {
        return IN_PROGRESS_FINISHED;
    }
}
